import { yupResolver } from '@hookform/resolvers/yup';
import RemoteError from 'components/form/remote-error';
import setGlobalFormErrors from 'components/form/util/set-global-form-errors';
import { useSentryContext } from 'contexts/sentry';
import Head from 'next/head';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import emailValidationSchema from 'validations/email';
import * as yup from 'yup';
import Button from '../../../components/button';
import Input from '../../../components/form/input';
import title from '../../../components/seo/title';
import MainHeadline from '../../../components/typography/main-headline';
import { useAuth } from '../../../contexts/auth';
import { Gtm, gtm } from '../../../tracking/gtm';

interface CustomerCredentials {
  email: string;
  password: string;
}

type Props = {
  callBack?: () => void;
};

const Login: React.FC<Props> = ({ callBack }) => {
  const { mergeContextData } = useSentryContext();
  const { onLogin, customer, onLogout } = useAuth();
  const intl = useIntl();

  //NOTE Hot fix : PT-2172
  const router = useRouter();
  const query = router.query;
  useEffect(() => {
    if (query?.forceLogout === '1') {
      onLogout();
    }
  }, [query, onLogout]);

  const validationSchema = yup.object({
    email: emailValidationSchema,
    password: yup.string().required('required'),
  });

  const callbackPath = React.useMemo(() => {
    if (callBack) return null;
    return (router.query.redirectUrl as string) || '/account';
  }, [callBack, router.query.redirectUrl]);

  const { register, handleSubmit, formState, setError } =
    useForm<CustomerCredentials>({
      defaultValues: {
        email: '',
        password: '',
      },
      mode: 'onSubmit',
      reValidateMode: 'onSubmit',
      resolver: yupResolver(validationSchema),
    });

  useCallback(() => {
    if (customer && callbackPath) {
      Router.push(callbackPath);
    }
  }, [customer, callbackPath]);

  const onSubmit: SubmitHandler<CustomerCredentials> = React.useCallback(
    async (formData) => {
      try {
        mergeContextData({ email: formData.email });
        await onLogin(formData);
        if (callbackPath) {
          Router.push(callbackPath);
        } else if (callBack) {
          callBack();
        } else {
          throw new Error('no call back or path is set');
        }
      } catch (error) {
        console.error(`login error ${JSON.stringify(error)}`);
        setGlobalFormErrors(error, setError);
      }
    },
    [mergeContextData, onLogin, callbackPath, callBack, setError]
  );

  return (
    <>
      <Head>
        {title(
          intl.formatMessage({
            id: 'auth:login:header',
          })
        )}
      </Head>
      <div className="container py-6 md:py-8 lg:py-10">
        <form onSubmit={handleSubmit(onSubmit)}>
          <MainHeadline>
            <h1 data-test="titel" className="text-center">
              <FormattedMessage id="auth:login:header" />
            </h1>
          </MainHeadline>

          <div className="mx-auto max-w-sm">
            <RemoteError errors={formState.errors} />
            <div className="pt-4">
              <Input
                data-test="email"
                type="email"
                label="E-Mail"
                name="email"
                autoCorrect="off"
                autoCapitalize="off"
                autoComplete="off"
                register={register}
                formState={formState}
              />
            </div>
            <div className="pt-4">
              <Input
                data-test="password"
                type="password"
                name="password"
                label={intl.formatMessage({
                  id: 'auth:login:label:password',
                })}
                autoCorrect="off"
                autoCapitalize="off"
                autoComplete="off"
                register={register}
                formState={formState}
              />
            </div>
            <Button
              data-test="submit"
              type="submit"
              className="mt-4 w-full"
              loading={formState.isSubmitting}
              onClick={() => {
                gtm({
                  group: Gtm.GroupName.Account,
                  name: Gtm.AccountEventName.Login,
                });
              }}
            >
              <FormattedMessage id="auth:login:button:login" />
            </Button>
            <div className="pt-3 text-center">
              <Link href="/reset-password" passHref>
                <a
                  data-test="password-reset"
                  className="text-text-light-bg-secondary hover:text-text-primary hover:underline"
                >
                  <FormattedMessage id="auth:login:button:recover-password" />
                </a>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
